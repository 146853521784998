const colors = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    50: '#F1F2F3',
    100: '#D8DBDF',
    200: '#BFC4CA',
    300: '#A6ADB5',
    400: '#8C95A0',
    500: '#737E8C',
    600: '#5C6570',
    700: '#454C54',
    800: '#2E3338',
    900: '#17191C',
  },
  red: {
    50: '#FBE9E9',
    100: '#F4C2C2',
    200: '#ED9C9C',
    300: '#E67575',
    400: '#DF4E4E',
    500: '#D82727',
    600: '#AD1F1F',
    700: '#821717',
    800: '#570F0F',
    900: '#2B0808',
  },
  blue: {
    50: '#E5EEFF',
    100: '#B8CFFF',
    200: '#8AB0FF',
    300: '#5C91FF',
    400: '#2E72FF',
    500: '#0053FF',
    600: '#0042CC',
    700: '#003299',
    800: '#002166',
    900: '#001133',
  },
  cyan: {
    50: '#E5FBFF',
    100: '#B8F4FF',
    200: '#8AEDFF',
    300: '#5CE6FF',
    400: '#2EDFFF',
    500: '#00D8FF',
    600: '#00ADCC',
    700: '#008299',
    800: '#005666',
    900: '#002B33',
  },
  teal: {
    50: '#E8FCFC',
    100: '#BFF8F7',
    200: '#96F3F2',
    300: '#6DEEED',
    400: '#44E9E8',
    500: '#1BE4E3',
    600: '#15B7B6',
    700: '#108988',
    800: '#0B5B5B',
    900: '#052E2D',
  },
  green: {
    50: '#EAFAF2',
    100: '#C5F1DA',
    200: '#A0E9C2',
    300: '#7BE0AB',
    400: '#56D793',
    500: '#31CE7B',
    600: '#27A563',
    700: '#1D7C4A',
    800: '#145231',
    900: '#0A2919',
  },
  yellow: {
    50: '#FBF5E9',
    100: '#F4E4C3',
    200: '#EDD29C',
    300: '#E6C075',
    400: '#DFAE4E',
    500: '#D79D28',
    600: '#AC7D20',
    700: '#815E18',
    800: '#563F10',
    900: '#2B1F08',
  },
  purple: {
    50: '#EFECF9',
    100: '#D3C9EE',
    200: '#B6A6E3',
    300: '#9A83D8',
    400: '#7D60CD',
    500: '#613DC2',
    600: '#4D309C',
    700: '#3A2475',
    800: '#27184E',
    900: '#130C27',
  },
  pink: {
    50: '#FBE9F3',
    100: '#F5C1DD',
    200: '#EF9AC8',
    300: '#E972B2',
    400: '#E24B9D',
    500: '#DC2387',
    600: '#B01C6C',
    700: '#841551',
    800: '#580E36',
    900: '#2C071B',
  },
};

export default colors;
